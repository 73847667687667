import React, { useState, useEffect } from 'react';
import './product.css';
import { Link } from 'react-router-dom';
import popularMilkImage1 from '../../assets/product_milkbt.png';
import popularMilkImage5 from '../../assets/product_milk.png';
import popularMilkImage2 from '../../assets/ghee2.webp';
import popularMilkImage3 from '../../assets/coming-soon.png';
import popularMilkImage4 from '../../assets/coming-soon.png';
import popularMilkImage6 from '../../assets/ghee2.webp';
import sliderImage1 from '../../assets/no20.jpg';
import pureMilkImage from '../../assets/milk collect.png';
import milkImage1 from '../../assets/bottle.png';
import gheeImage from '../../assets/ghee2.webp';

const Product = () => {
  // Slider images array
  const sliderImages = [sliderImage1];
  const [currentSlide, setCurrentSlide] = useState(0);

  // Auto-slide functionality
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderImages.length);
    }, 3000); // change every 3 seconds
    return () => clearInterval(interval);
  }, [sliderImages.length]);

  return (
    <div className="main-product-container">
      {/* Image Slider */}
      <div className="slider-container">
        <img src={sliderImages[currentSlide]} alt="Product Slider" className="slider-image" />
      </div>

      <section className="pure-milk-section">
  <div className="pure-milk-container">
    {/* First Column */}
    <div className="pure-milk-image-column">
      <div className="image-wrapper">
        <img src={pureMilkImage} alt="Milk" className="pure-milk-image" />
        <p className="top-left-label">No Harmful Sprays</p>
        <p className="top-right-label">Completely Organic Quality</p>
        <p className="bottom-center-label">Grown the Natural Way</p>
      </div>
    </div>

    {/* Second Column */}
    <div className="pure-milk-content-column">
      {/* Row 1: Heading */}
      <h2 className="pure-milk-heading">Pure Milk - Fresh & Organic</h2>

      {/* Row 2: List */}
      <ul className="pure-milk-list">
        <li>
          <span className="cow-icon">🐄</span> Farm-fresh milk directly from cows
        </li>
        <li>
          <span className="cow-icon">🐄</span> No preservatives or additives
        </li>
        <li>
          <span className="cow-icon">🐄</span> Packed with essential nutrients
        </li>
      </ul>

      {/* Row 3: Button */}
      <Link to="/shop">
        <button className="explore-products-button">Explore Products</button>
      </Link>
    </div>
  </div>
</section>

      <section className="product-section">
        <h2 className="product-heading">Explore our Premium Products</h2>
        <div className="product-cards">
          <Link to="/shop" className="product-card">
            <img src={popularMilkImage1} alt="Fresh Milk" className="product-image1" />
            <h3 className="product-name">Milk</h3>
            <p className="product-price">₹70.00/1000ml</p>
            <p className="product-description">Pure and fresh cow milk directly from the farmer.</p>
          </Link>

          <Link to="/shop" className="product-card">
            <img src={popularMilkImage5} alt="Fresh Milk" className="product-image1" />
            <h3 className="product-name">Milk</h3>
            <p className="product-price">₹38.00/500ml</p>
            <p className="product-description">Pure and fresh cow milk directly from the farmer.</p>
          </Link>

          <Link to="/shop" className="product-card">
            <img src={popularMilkImage4} alt="Ghee" className="product-image1" />
            <h3 className="product-name">Ghee</h3>
            <h3>Coming Soon.</h3>
            {/* <p className="product-price">₹999.00/1000ml</p>
            <p className="product-description">Made from 100% pure cow's milk.</p> */}
          </Link>

          <Link to="/shop" className="product-card">
            <img src={popularMilkImage3} alt="Ghee" className="product-image1" />
            <h3 className="product-name">Ghee</h3>
            <h3>Coming Soon.</h3>
            {/* <p className="product-price">₹499.00/500ml</p>
            <p className="product-description">Made from 100% pure cow's milk.</p> */}
          </Link>

          <Link to="/shop" className="product-card">
            <img src={popularMilkImage3} alt="Paneer" className="product-image1" />
            <h3 className="product-name">Paneer</h3>
            <h3>Coming Soon.</h3>
          </Link>

          <Link to="/shop" className="product-card">
            <img src={popularMilkImage4} alt="Chekku Oil" className="product-image1" />
            <h3 className="product-name">Chekku Oil</h3>
            <h3>Coming Soon.</h3>
          </Link>
        </div>
      </section>

      <section className="products-section">
  <div className="products-container">
    {/* Product 1: Fresh & Pure Cow Milk */}
    <div className="product1">
      <div className="product1-content">
        <h3>Fresh & Pure Cow Milk</h3>
        <p>Poovanam Org Agro delivers you fresh, hygienic, and healthy milk.</p>
        <ul>
          <li>0% Preservatives</li>
          <li>0% Water</li>
          <li>No Pasteurization & Homogenization</li>
          <li>Packed in 500ml & 1000ml</li>
        </ul>
      </div>
      <div className="product1-image">
        <img src={milkImage1} alt="Fresh & Pure Cow Milk" />
      </div>
    </div>

    {/* Product 2: Fresh Ghee */}
    <div className="product1">
      <div className="product1-content">
        <h3>Fresh Ghee</h3>
        <p>
          Ghee is a class of clarified butter traditionally made from pure cow milk. 
          The cow milk is agitated to separate the cream, which is boiled on low flame. 
          Drumstick leaves are added to enhance flavor, health benefits, and tradition.
        </p>
      </div>
      <div className="product1-image">
        <img src={gheeImage} alt="Fresh Ghee" />
      </div>
    </div>
  </div>
</section>

    </div>
  );
};

export default Product;
