import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import BackToTop from './BackToTop';
import Home from './Pages/Home/Home';
import About from './Pages/About/about';
import Product from './Pages/Products/product';
import Shop from './Pages/Shop/shop';
import Cart from './Pages/Cart/cart';
import Checkout from './Pages/Checkout/checkout';
import Contact from './Pages/Contact/contact';
import ScrollToTop from './ScrollToTop';
import './App.css';

const ErrorBoundary = ({ children }) => (
  <React.Suspense fallback={<div>Loading...</div>}>
    {children}
  </React.Suspense>
);

function App() {
  return (
    <Router>
      {/* Ensure scrolling to the top of the page on route change */}
      <ScrollToTop />
      <>
        {/* Navbar displayed on all pages */}
        <Navbar />
        {/* Back-to-top button */}
        <BackToTop />
        <Routes>
          {/* Define routes for your application */}
          <Route path="/" element={<ErrorBoundary><Home /></ErrorBoundary>} />
          <Route path="/about" element={<ErrorBoundary><About /></ErrorBoundary>} />
          <Route path="/product" element={<ErrorBoundary><Product /></ErrorBoundary>} />
          <Route path="/shop" element={<ErrorBoundary><Shop /></ErrorBoundary>} />
          <Route path="/cart" element={<ErrorBoundary><Cart /></ErrorBoundary>} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/contact" element={<ErrorBoundary><Contact /></ErrorBoundary>} />
        </Routes>
        {/* Footer displayed on all pages */}
        <Footer />
      </>
    </Router>
  );
}

export default App;
