import React from "react";
import Slider from "react-slick";
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import slide1 from "../../assets/hero_image.png";
import slide2 from "../../assets/hero_image1.png";

import milk from "../../assets/bottle1.png";
import ghee from "../../assets/coming-soon.png";
import chekkuOil from "../../assets/coming-soon.png"; // Placeholder for Chekku Oil image

const Home = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: false,
    pauseOnFocus: false,
  };

  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero">
        <Slider {...sliderSettings}>
          <div>
            <img src={slide1} alt="Slide 1" className="slider-image" />
          </div>
          <div>
            <img src={slide2} alt="Slide 2" className="slider-image" />
          </div>
        </Slider>
      </section>

      <Link to="/product" className="products-link">
      <section className="products-section">
        <h2 className="products-title">Our Products</h2>
        <div className="products-grid">
          {/* Card 1: Pure Cow Milk */}
          <div className="product-card">
            <div className="product-image">
              <img src={milk} alt="Pure Cow Milk" />
            </div>
            <div className="product-details">
              <h3 className="product-name">Pure Cow Milk</h3>
              <p className="product-description">
                Fresh, hygienic, and chemical-free milk collected from local farmers.
              </p>
              <p className="product-price">1000ml - ₹70 | 500ml - ₹38</p>
            </div>
          </div>

          {/* Card 2: Pure Cow Ghee */}
          <div className="product-card">
            <div className="product-image">
              <img src={ghee} alt="Pure Cow Ghee" />
            </div>
            <div className="product-details">
              <h3 className="product-name">Pure Cow Ghee</h3>
              <p className="product-description">
                Traditionally made clarified butter from pure cow milk.
              </p>
              {/* <p className="product-price">1000ml - ₹999 | 500ml - ₹499</p> */}
              <p className="product-price">Coming Soon</p>
            </div>
          </div>

          {/* Card 3: Chekku Oil (Coming Soon) */}
          <div className="product-card coming-soon">
            <div className="product-image">
              <img src={chekkuOil} alt="Chekku Oil" />
            </div>
            <div className="product-details">
              <h3 className="product-name">Chekku Oil</h3>
              <p className="product-description">
                Cold-pressed oil, naturally extracted and chemical-free.
              </p>
              <p className="product-price">Coming Soon</p>
            </div>
          </div>
        </div>
      </section>
    </Link>


    </div>
  );
};

export default Home;
